<template>
  <div class="app-container">
    <!--    搜索区域-->
    <el-card class="filter-container" shadow="never">
      <div>
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="昵称：">
            <el-input style="width: 230px" v-model="listQuery.keyword" placeholder="请输入用户名" clearable></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-left: 55px;">
        <el-button type="primary" size="small" @click="handleSearchList()">查询</el-button>
        <el-button size="small" @click="handleResetSearch()">重置</el-button>
      </div>
    </el-card>
    <!--    功能区域-->
    <div style="margin: 20px 0">
<!--      <el-button type="primary" @click="handleAdd" >添加</el-button>-->
      <el-button type="danger" @click="deleteBatch" >批量删除</el-button>
    </div>
    <!--    表格区域-->
    <div class="table-container">
      <el-table ref="userDownTable"
                :data="tableData"
                style="width: 100%;"
                @selection-change="handleSelectionChange"
                v-loading="listLoading" border>
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column label="编号" width="120" align="center">
          <template #default="scope">{{scope.row.id}}</template>
        </el-table-column>
        <el-table-column label="用户ID"  align="center" width="180">
          <template #default="scope">{{scope.row.user_id}}</template>
        </el-table-column>
        <el-table-column label="昵称"  align="center">
          <template #default="scope">{{scope.row.nick_name}}</template>
        </el-table-column>
        <el-table-column label="下载时间" align="center">
          <template #default="scope">{{scope.row.down_time}}</template>
        </el-table-column>
        <el-table-column label="下载内容" align="center">
          <template #default="scope">{{scope.row.file_name}}</template>
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template #default="scope">
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    分页-->
    <div style="margin: 10px 0">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes,prev, pager, next,jumper"
          :page-size="listQuery.pageSize"
          :page-sizes="[5,10,15,20,50,100,200,500,1000]"
          :current-page.sync="listQuery.pageNum"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog
        :title="isEditFlag == true?'编辑':'添加'"
        v-model="dialogVisible"
        width="40%">
      <el-form :model="userDownModel"
               ref="userDownFrom"
               label-width="150px"
               size="small">
        <el-form-item label="用户名：">
          <el-input v-model="userDownModel.user_id" style="width: 80%" clearable></el-input>
        </el-form-item>
        <el-form-item label="下载内容：" >
          <el-input v-model="userDownModel.down_msg" style="width: 80%" clearable></el-input>
        </el-form-item>
        <el-form-item label="时间：" >
          <el-date-picker v-model="userDownModel.down_time" value-format="YYYY-MM-DD hh:mm:ss" type="datetime" style="width: 80%" clearable></el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleDialogConfirm('userDownFrom')">提交</el-button>
          </span>
      </template>
    </el-dialog>


  </div>
</template>

<script>

import {batchDeleteUserDown, deleteUserDown, fetchList, createUserDown} from "@/api/userDown";

const defaultListQuery = {
  pageNum: 1,
  pageSize: 5,
  keyword: null,
};

const defaultUserDown = {
  user_id: null,
  down_msg: null,
  down_time: null,
};

export default {
  name: 'UserDown',
  components: {},
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      tableData: [],
      total: null,
      listLoading: false,
      ids: [],
      dialogVisible: false,
      isEditFlag: false,
      userDownModel: Object.assign({}, defaultUserDown),
    }
  },
  created() {
    this.getTableList()
  },
  methods: {
    //重置
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery);
      this.getTableList();
    },
    //查询
    handleSearchList() {
      this.listQuery.pageNum = 1;
      this.getTableList();
    },
    //多选
    handleSelectionChange(val){
      this.ids = val.map(v => v.id);
    },
    // 改变当前每页的个数触发
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getTableList();
    },
    // 改变当前页码触发
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getTableList();
    },
    //删除
    handleDelete(row){
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data =  {
          "dataId": row.id,
        };
        deleteUserDown(data).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "删除成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //批量删除
    deleteBatch() {
      if (!this.ids.length) {
        this.$message.warning("请选择数据！")
        return
      }
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data =  {
          "dataId": this.ids,
        };
        batchDeleteUserDown(data).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "批量删除成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //列表
    getTableList() {
      this.listLoading = true;
      fetchList(this.listQuery).then(res => {
            if (res.code === '0') {
              this.listLoading = false;
              this.tableData = res.data.records;
              this.total = res.data.total;
            }
      })
    },

    //添加路由
    handleAdd() {
      this.dialogVisible = true;
      this.isEditFlag = false;
      this.userDownModel = Object.assign({},defaultUserDown);
    },
    //确认操作
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            createUserDown(this.userDownModel).then(res => {
              if (res.code === '0') {
                this.$message({
                  message: '提交成功',
                  type: 'success',
                });
              }else {
                this.$message({
                  type: "error",
                  message: res.msg
                })
              }
              this.dialogVisible =false;
              this.getTableList();
            });
          });
        }
      });
    },

  }
}
</script>
<style scoped>


</style>
