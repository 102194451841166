import request from '@/utils/request';

//列表
export function fetchList(data) {
  return request({
    url : '/user/down/list.php',
    method : 'post',
    data : data
  })
}

//删除
export function deleteUserDown(data) {
  return request({
    url : '/user/down/delete.php',
    method : 'post',
    data : data
  })
}
//批量删除
export function batchDeleteUserDown(data) {
  return request({
    url : '/user/down/deleteBatch.php',
    method : 'post',
    data : data
  })
}

//新增
export function createUserDown(data) {
  return request({
    url : '/user/down/save.php',
    method : 'post',
    data : data
  })
}
